import React, { Component, PureComponent } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts';


type ChartProps = {
    data: any;
};

  export default class Example extends Component<ChartProps> {

    render() {
    return (
        <LineChart
  width={1000}
  height={400}
  data={this.props.data}
  margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
>
  <XAxis dataKey="date" />
  <Tooltip />
  <CartesianGrid stroke="#f5f5f5" />
  <Line type="monotone" dataKey="impressions" stroke="#ff7300" yAxisId={0} />
  <Line type="monotone" dataKey="click_throughs" stroke="#3a34eb" yAxisId={0} />
</LineChart>);
}
}

import React, { useEffect, useState } from "react";
import axios from "axios";

import moment from "moment";

import LineChartComponent from "./LineChart";

import "./Dashboard.css";

const ExampleJson = `{"campaign": {"_id": {"$oid": "63f8fa08fdfa09f85e1a93c5"}, "id": "1234", "active": true, "bid": 999.0, "budget": 1000.0}, "impressions": {"total": 2, "unique": 2, "results": [{"_id": {"$oid": "63fb7e2a2003688b9f9942b0"}, "lead": {"content_provider_id": "1", "content_metadata": "1234", "user_metadata": {"user_agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36", "ip": "76.25.180.141"}, "id": "09875e4967f8439f82db6f8e4e685b83", "ref": "da62fc901363419b97589ec708a40f58", "created": {"$date": "2023-02-26T15:43:38.757Z"}, "_id": {"$oid": "63fb7e2a2003688b9f9942ad"}}, "ad_id": "5678", "campaign_id": "1234", "id": "5465f6d2312c4b5ebb77b2d6de93c007", "created": {"$date": "2023-02-26T15:43:38.910Z"}}, {"_id": {"$oid": "63fb870e665af85d5483558c"}, "lead": {"content_provider_id": "1", "content_metadata": "1234", "user_metadata": {"user_agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36", "ip": "76.25.180.141"}, "id": "c400e367303d47a5a309f590b19ea544", "ref": "0b4bea9d4a554566b3e2a11abb02b9d4", "created": {"$date": "2023-02-26T16:21:34.144Z"}, "_id": {"$oid": "63fb870e665af85d54835589"}}, "ad_id": "5678", "campaign_id": "1234", "id": "5cef096287804957b4d249ed6c39645e", "created": {"$date": "2023-02-26T16:21:34.302Z"}}]}, "click_throughs": []}`;

function Dashboard() {
  const [data, setData] = useState<any>({"campaign": {"id": ""}, "impressions": {"total": 0, "unique": 0, "results": [], "results_by_day": {}}, "click_throughs": []});
  const [error, setError] = useState<any>(null);
  const [page, setPage] = useState<number>(1);

  const fetchData = async (c_page: string) => {
    try {
      const result = await axios.get(`https://ads.agathonx.com/campaign/dashboard/?campaign_id=1234&page=${c_page}`);
      // const result = { data: JSON.parse(ExampleJson) };
      setData(result.data);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchData(""+1);
  }, []);

  const pageNext = async () => {
    setPage(page+1)
    fetchData(""+(page+1));
  };

  const pagePrev = async () => {
    setPage(page-1 < 1 ? 1 : page-1)
    const f_page = page-1 < 1 ? 1 : page-1
    fetchData(""+f_page);
  };

  return (
    <div>
      <h1>Campaign Dashboard: {data["campaign"]["id"]}</h1>
        <div className="chart">
            <LineChartComponent data={data["impressions"]["results_by_day"]} />
        </div>
      <table className="full-width">
        <thead>
            <tr>
                <th>Date</th>
                <th>Impression Lead ID</th>
                <th>Impression IP Address</th>
                <th>Impression User-Agent</th>
            </tr></thead>
                
        <tbody>
            {data["impressions"]["results"].map((impression: any) => (
                <tr key={impression["id"]}>
                    <td>{(moment(impression["created"]["$date"]).format("MMMM Do YYYY, h:mm:ss a"))}</td>
                    <td><div className="truncate-short">{impression["lead"]["id"]}</div></td>
                    <td>{impression["lead"]["user_metadata"]["ip"]}</td>
                    <td><div className="truncate">{impression["lead"]["user_metadata"]["user_agent"]}</div></td>
                </tr>
            ))}
        </tbody>
    </table>
    <ul className="pager">
        <li className="previous disabled">
            <a href="#" onClick={pagePrev}>&larr; Previous</a>
        </li>
        <li className="next">
            <a href="#" onClick={pageNext}>Next &rarr;</a>
        </li>
    </ul>
    </div>
  );
}



  
export default function Gallery() {
    return (
        <section>
        <Dashboard />
        </section>
    );
}